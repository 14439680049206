import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Banner from "../components/Banner"
import {
  Section,
  SectionText,
  TwoColumn,
  TopHeader,
} from "../components/Section"
import Seo from "../components/Seo"

export default function markets() {
  return (
    <Layout>
      <Seo
        title="Markets"
        description="We have a vast demolition experience for commercial, municipal and residential buildings in lower mainland."
      />
      <Section>
        <TopHeader>
          <h1>Markets</h1>
          <h2>
            Whether you are a homeowner or a business proprietor, you can rely
            on our expertise to demolish residential and commercial buildings,
            or any other renovation project.
          </h2>
        </TopHeader>
        <TwoColumn>
          <SectionText>
            <h3>Commercial</h3>
            <p>
              Commercial properties are undoubtedly heavily used by employees
              and customers on a daily basis, thus requiring both maintenance
              and a high health standard. This is simply not possible with the
              presence of asbestos. A&D Demolition offers asbestos removal for
              commercial properties ranging from small to large as well as
              commercial demolition in Vancouver. We service any commercial
              property – from schools and office buildings to warehouses and
              clinics.
            </p>
            <p>
              A dangerous material such as asbestos is best left in the hands of
              our experts. Our team consists of highly trained and experienced
              professionals who will always make sure the job is done on time,
              safely, and up to the highest health standards. We will thoroughly
              evaluate the site and communicate the process, safety procedures
              to ensure the smooth-sailing and quality job we pride ourselves
              on.
            </p>
          </SectionText>
          <StaticImage
            src="../images/commercial.png"
            alt="Building skeleton"
            placeholder="blurred"
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
        </TwoColumn>
      </Section>
      <Section>
        <TwoColumn>
          <StaticImage
            src="../images/municipal.jpg"
            alt="A demolished house"
            placeholder="blurred"
            height={600}
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
          <SectionText>
            <h3>Municipal</h3>
            <p>
              A&D Demolition provides asbestos removal services to municipal
              buildings in the Lower Mainland. Asbestos was widely used in the
              construction of buildings before the 90’s in every market – be it
              commercial, residential or municipal and became the standard
              before the detrimental health effects were known.
            </p>
            <p>
              Municipal spaces need to be maintained at the highest standard of
              health and safety for both the employees and citizens using the
              services. For A&D’s asbestos removal, this means a thorough
              evaluation, open communication and a highly trained team of
              experts in the field who will ensure the job is done right and on
              time, all while meeting health and safety regulations. You can
              count on A&D Demolition to provide you with the very best.
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
      <Section>
        <TwoColumn>
          <SectionText>
            <h3>Residential</h3>
            <p>
              Due to the high volume of homes constructed with asbestos from the
              20’s all the way to the 90’s, many homes still contain this toxic
              material. Unaware homeowners are just now beginning to catch on to
              what asbestos is and just just how toxic it is. A&D provides
              commercial and residential demolition in Vancouver as well as
              asbestos removal.
            </p>
            <p>
              If your home was built before the 70’s, the chances of it
              containing asbestos is high and it is important for it to be
              evaluated. Living on a property where asbestos is present can lead
              to a whole host of health problems and is vital for it to be
              removed. Your family’s health is the most important thing – just
              make the call, and let A&D Demolition take care of the rest.
            </p>
          </SectionText>
          <StaticImage
            src="../images/residential.jpg"
            alt="A bulldozer near a wall"
            placeholder="blurred"
            height={600}
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
        </TwoColumn>
      </Section>
      <Section>
        <Banner>
          <h3>Free asbestos sampling and testing for renovations</h3>
          <p>
            We offer FREE asbestos sampling and testing for renovations that get
            sent to our lab partner, so you can begin your project with peace of
            mind.
          </p>
          <a href="www.google.ca">Contact us</a>
        </Banner>
      </Section>
      <Section>
        <TwoColumn>
          <SectionText>
            <h3>All about asbestos</h3>
            <p>
              Asbestos removal services are often demanded by people for old
              constructed buildings. In early 20’s to the 90’s, Asbestos was
              widely used in construction materials like reinforcing components
              or as material for thermal, electrical or acoustic insulation. It
              is capable of silently causing mesothelioma (cancer that affects
              the lining of the lungs and the lining surrounding the lower
              digestive tract), lung cancer, asbestosis and pleural thickening.
              These four diseases often take more than a decade to develop.
            </p>
            <p>
              Asbestos removing is not a simple matter. It not only takes a lot
              of strength and time, but it also requires an extensive amount of
              knowledge, experience, testing, technique and expertise, as it is
              hard to detect because asbestos is often mixed with other
              materials. Asbestos removal is a job that should be done by an
              expert and certified team.
            </p>
          </SectionText>
          <SectionText>
            <h3>Our expertise</h3>
            <p>
              We ensure that all asbestos waste and any other contaminated waste
              is arranged in sealed containers and labelled properly. According
              to Safe Work Practices for Handling Asbestos, asbestos must be
              double bagged in 6 mil poly bags, sealed with duct tape and label.
              A prior settlement should be done with the authorities to
              transport any hazardous waste to assigned landfills.
            </p>
            <p>
              Our experience enables us to do a hassle free job, always
              considering the environment and trying to recover all materials
              that can be recycled and reused. If you want additional
              information about asbestos and how to properly handle it, please
              visit www.worksafebc.com. If you need a professional, effective
              and skilled asbestos removal team in Vancouver, we can help you in
              your demolition or renovation project. Do not hesitate to contact
              us!
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
    </Layout>
  )
}
